<template><div><h1 id="making-a-bootable-usb" tabindex="-1"><a class="header-anchor" href="#making-a-bootable-usb"><span>Making a Bootable USB</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Flashing Ventoy or ISOs to an external drive will <strong>wipe all data</strong> on said drive. Back up anything if you need to.</p>
</div>
<h2 id="prerequisites" tabindex="-1"><a class="header-anchor" href="#prerequisites"><span>Prerequisites</span></a></h2>
<ol>
<li>Another PC or laptop with at least 8GB storage</li>
<li>An internet connection</li>
<li>An external drive like a USB drive or SD card that is at least 8GB.</li>
</ol>
<h2 id="downloading-a-iso" tabindex="-1"><a class="header-anchor" href="#downloading-a-iso"><span>Downloading a ISO</span></a></h2>
<ol>
<li>Determine what OS you want.
<ul>
<li>For Linux, keep in mind Ubuntu and Ubuntu-based derivatives <strong>are not supported</strong>.</li>
<li>For Windows, keep in mind only official versions of Windows 10 and newer are supported.</li>
</ul>
</li>
<li>Place the ISO in a safe place.</li>
</ol>
<h2 id="flashing-using-rufus-windows" tabindex="-1"><a class="header-anchor" href="#flashing-using-rufus-windows"><span>Flashing using Rufus (Windows)</span></a></h2>
<p>For this guide, we will be using Rufus to create our bootable USB. Rufus is a useful tool that allows you to flash ISOs to an external drive.</p>
<ol>
<li>
<p>Download Rufus from <a href="https://rufus.ie/en/" target="_blank" rel="noopener noreferrer">here</a>. The standard Windows x64 version will do.</p>
<p><img src="/rufus.png" alt="image"></p>
</li>
<li>
<p>Plug in your external drive.</p>
</li>
<li>
<p>Locate and launch Rufus. It should ask you to launch with administrator privileges, select YES.</p>
</li>
<li>
<p>Press the &quot;SELECT&quot; button on the right-hand side.</p>
</li>
<li>
<p>A File Explorer window should pop up, find your ISO of choice, then click &quot;Open&quot;.</p>
</li>
<li>
<p>Click &quot;Start&quot;. The default options should work just fine.</p>
</li>
<li>
<p>Wait for the green bar to say &quot;READY&quot;, then click &quot;Close&quot; and eject your newly made external drive.</p>
</li>
<li>
<p>Profit</p>
</li>
</ol>
<h2 id="flashing-using-ventoy-windows-linux-macos" tabindex="-1"><a class="header-anchor" href="#flashing-using-ventoy-windows-linux-macos"><span>Flashing using Ventoy (Windows/Linux/macOS)</span></a></h2>
<p>For this guide, we will be using Ventoy to create our bootable USB.
Ventoy allows you to boot multiple ISO's from a single drive.</p>
<ol>
<li>
<p>Download and extract the latest version from <a href="https://www.ventoy.net/en/download.html" target="_blank" rel="noopener noreferrer">Github</a>.</p>
<ul>
<li>Download the respective file for your platform.
<img src="/ventoy/download-alt.png" alt="image"></li>
</ul>
</li>
<li>
<p>Run <code v-pre>Ventoy2Disk.exe</code> for Windows or <code v-pre>VentoyGUI.x86_64</code> for Linux.</p>
<ul>
<li>Make sure to allow administrator privilages when prompted.</li>
</ul>
</li>
<li>
<p>Plug in your external drive and click the green circle to refresh devices. It should pick up your external drive.</p>
</li>
<li>
<p>Click Options &gt; Partition Style and select &quot;GPT&quot;.</p>
</li>
<li>
<p>Once you have done Step 4, click &quot;Install&quot; and click ok twice to start the install.</p>
</li>
<li>
<p>Open your Files App and find a drive that's labeled as &quot;Ventoy&quot;.</p>
</li>
<li>
<p>Copy the ISO you downloaded into that drive, after that eject it.</p>
</li>
<li>
<p>Profit</p>
</li>
</ol>
<h2 id="flashing-with-dd-linux-macos" tabindex="-1"><a class="header-anchor" href="#flashing-with-dd-linux-macos"><span>Flashing with dd (Linux/macOS)</span></a></h2>
<ol>
<li>Plug in your external drive</li>
<li>Run <code v-pre>lsblk</code> to help determine the drive to be flashed. If the device is mounted you will need to use <code v-pre>umount</code> to unmount it.</li>
<li>Flash with <code v-pre>dd if=myfile.iso of=/dev/&lt;device name (as shown by lsblk)&gt; bs=16M status=progress</code>.</li>
</ol>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Be sure to target the drive itself and not a partition.</p>
</div>
</div></template>


